import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/dian-astrid-chevalier-oneal.png";
  const fullName = `Dian Astrid Chevalier O'Neal`;
  const zoomUrl =
    "https://us02web.zoom.us/j/87468833490?pwd=M3BnaFVhMTFwOFY3YXNFWjU5M1M5QT09";
  const zoomPhoneNumber = "(646) 558 8656";
  const zoomMeetingId = "874 6883 3490";
  const zoomMeetingPasscode = "835990";
  const birthDate = new Date(1942, 1 - 1, 23);
  const deathDate = new Date(2021, 1 - 1, 30);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 2 - 1, 13);
  const memorialTime = "3pm EST";
  const inviteText = `Dian O'Neal passed away on Jan 30, 2021. She was an extraordinary wife, mother, and friend who was full of passion and love, and whose radiant spirit will shine eternal. When we can all be together - we will. And we will raise a toast to a remarkable woman. For now, in lieu of a physical ceremony, the family of Dian invites you to a virtual service in celebration of her life and contribution.`;
  const inviteChild = <></>;
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Dian%20O'Neal%20Recording.mp4",
      title: "Memorial Recording",
      poster: "/images/people/DianIntro.png",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialTitle="Virtual Memorial"
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    >
      <h2>
        <strong>Dian's Obituary</strong>
      </h2>
      <p>
        The family of Dian Astrid Chevalier O’Neal is sad to announce her
        passing on January 31, 2021 at her home in Oakmont Village, Santa Rosa.
        She died of heart failure at age 79.
      </p>
      <p>
        Born on January 23, 1942 in Bronxville, New York, Dian spent the
        majority of her life in Connecticut, before moving to Sonoma in 2008.
        She leaves behind her husband, Bill O’Neal, her three sons, Dwayne, Sean
        and Bret O’Neal, her niece, Doreen Fender and her three grandchildren,
        Ella, Logan and Ethan O’Neal.
      </p>
      <p>
        Besides being a great wife and mother, Dian had a remarkable career,
        starting at the University of Miami where she first displayed her flair
        for writing. She leveraged that talent into an early career in
        advertising, first in Miami and then in Connecticut.
      </p>
      <p>
        Her love of horses then took over. An avid equestrian since childhood,
        she turned that passion into a full-time business, importing horses from
        Europe, training them in the classical art of dressage at her barn{" "}
        <i>The Ridge</i>, in Durham, CT, then selling them to eager American
        riders. All the while she continued to ride and train her own horses.
      </p>
      <p>
        Her other great passion, naturopathic medicine, then took over. She went
        back to school, this time to the University of Bridgeport’s College of
        Naturopathic Medicine, supplemented by summer programs at Ohio State and
        University of Nebraska. In 1995, a newly-minted Dr. Dian O’Neal opened
        her office, <i>Natural Healthcare</i>, in Durham, CT.
      </p>
      <p>
        Dian closed her <i>Natural Healthcare</i> practice in 2008 to move to
        Sonoma, where she could ride year-round without shoveling snow. It was
        in Sonoma that she acquired her pride and joy, her magnificent
        Lipizzaner <i>Siglavy</i>.
      </p>
      <p>
        “Dian was the most amazing person I’ve ever known,” says her husband.
        “She could walk through walls. There was nothing she couldn’t do if she
        set her sights on it.”
      </p>

      <p>
        Memorial contributions can be made in Dian’s name to Sonoma Equine
        Rescue, Rehab & Adoption (
        <a href="//www.serraequinerescue.org" target="_blank" rel="noreferrer">
          www.serraequinerescue.org
        </a>
        ), 1971 Crane Canyon Rd, Santa Rosa, CA 95404.
      </p>
    </StoryPage>
  );
};

export default ThisPage;
